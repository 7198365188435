<template>
  <div>
    <nav-filter
      class="mb-10"
      :title="$t('form.choose_class')"
      :filters="filters"
      :loading="loadingFilters"
      :collapseAction="true"
      @reFilter="reFilter" />

    <!-- Table -->
    <gradeBookTable />
  </div>
</template>
<script>
// Components
import NavFilter from '@/components/general/NavFilter.vue'
import gradeBookTable from '@/modules/grading/components/gradeBookTable/gradeBookTable.vue'

// mixins
import {commonDataMixin} from '@/mixins.js'

export default {
  name: 'gradebook',
  mixins: [commonDataMixin],
  components: {
    NavFilter,
    gradeBookTable
  },
  data() {
    return {
      classes: [],
      loadingClasses: false,
      loadingFilters: true,
      /////////////////////
      //  Filters
      /////////////////////
      filters: [
        // {
        //   id: 0,
        //   name: this.$t('basic.academic_year'),
        //   payload_name: 'academics',
        //   onChangeFireAction: true,
        //   by: []
        // },

        {
          id: 1,
          name: this.$t('basic.schools'),
          payload_name: 'schools',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 2,
          name: this.$t('basic.divisions'),
          payload_name: 'divisions',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 3,
          name: this.$t('basic.sections'),
          payload_name: 'sections',
          onChangeFireAction: true,
          by: []
        },
        {
          id: 4,
          name: this.$t('basic.grades'),
          payload_name: 'grades',
          onChangeFireAction: true,

          by: []
        },

        {
          id: 6,
          name: this.$t('basic.class_groups'),
          payload_name: 'class_groups',
          by: []
        }
      ],
      filtersChoosen: {}
    }
  },
  computed: {
    selectedClass() {
      return this.$store.getters.getSelectedClass || {}
    }
  },
  methods: {
    /////////////////////////
    // Filter
    /////////////////////////
    reFilter(filters) {
      this.filtersChoosen = filters
      // Quick class select
      // filters = {class_groups:{id:37}}
      const isClassSelected = Boolean(filters.class_groups?.id),
        classNotChanged =
          isClassSelected && this.selectedClass.id === filters.class_groups?.id

      if (!classNotChanged)
        this.$store.commit('gradingSelectClass', {
          ...filters?.class_groups,
          grade_id: filters?.grades?.id,
          section_id: filters?.sections?.id,
          division_id: filters?.divisions?.id
        })
      else this.$store.commit('gradingSelectClass', null)
    },
    resetFilter(filter) {
      const startIndex = this.filters.findIndex(
        (e) => e.payload_name === filter
      )
      for (let i = startIndex; i < this.filters.length; i++) {
        this.filters[i].by = []
      }
    },

    /////////////////////////
    // API
    /////////////////////////
    getFilters(
      payload = null,
      targetResult = 'schools',
      targetFilter = 'schools'
    ) {
      this.loadingFilters = true
      this.getFilterData(payload)
        .then((res) => {
          res[targetResult].map((el) => {
            const targetFilterIndex = this.filters.findIndex(
              (e) => e.payload_name === targetFilter
            )
            this.filters[targetFilterIndex]['by'].push({
              id: el.id,
              name: el.branch_name || el.name
            })
          })
        })
        .finally(() => {
          this.loadingFilters = false
        })
    },

    getAcademics() {
      this.loadingFilters = true

      this.getAllAcademic()
        .then((res) => {
          res.map((el) => {
            this.filters
              .find((e) => e.payload_name === 'academics')
              ['by'].push({
                id: el._id,
                name: el.name
              })
          })
        })
        .finally(() => {
          this.loadingFilters = false
        })
    },
    getSchools() {
      this.getFilters()
      // this.resetFilter('schools')
      // this.getFilters({academic_year_id: [this.filtersChoosen.academics.id]})
    },
    getDivision() {
      const data = {
        schools: [this.filtersChoosen.schools?.id || 0]
      }

      this.resetFilter('divisions')
      this.getFilters(data, 'divisions', 'divisions')
    },
    getSections() {
      this.resetFilter('sections')

      const data = {
        schools: [this.filtersChoosen.schools?.id || this.authUser.school_id],
        divisions: [this.filtersChoosen.divisions?.id || 0]
      }

      this.getFilters(data, 'sections', 'sections')
    },
    getGrades() {
      this.resetFilter('grades')

      const data = {
        schools: [this.filtersChoosen.schools?.id || this.authUser.school_id],
        divisions: [this.filtersChoosen.divisions?.id || 0],
        sections: [this.filtersChoosen.sections?.id || 0]
      }
      this.getFilters(data, 'grades', 'grades')
    },
    getClasses() {
      this.resetFilter('class_groups')

      const data = {
        schools: [this.filtersChoosen.schools?.id || this.authUser.school_id],
        divisions: [this.filtersChoosen.divisions?.id || 0],
        grades: [this.filtersChoosen.grades?.id || 0],
        sections: [this.filtersChoosen.sections?.id || 0]
      }
      this.getFilters(data, 'grades_classes', 'class_groups')
    }
  },
  created() {
    this.getAcademics()
    this.getSchools()

    this.$root.$on('onChange:academics', () => {
      this.getSchools()
    })
    this.$root.$on('onChange:schools', () => {
      this.getDivision()
    })
    this.$root.$on('onChange:divisions', () => {
      this.getSections()
    })
    this.$root.$on('onChange:sections', () => {
      this.getGrades()
    })
    this.$root.$on('onChange:grades', () => {
      this.getClasses()
    })
  },
  beforeDestroy() {
    this.$root.$off('onChange:academics')
    this.$root.$off('onChange:schools')
    this.$root.$off('onChange:divisions')
    this.$root.$off('onChange:sections')
    this.$root.$off('onChange:grades')
    // this.$store.commit('gradingSelectClass', null)
  }
}
</script>
